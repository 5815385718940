import React, { useEffect, useState } from "react";
import { Tab } from "react-bootstrap";
import moment from "moment";
import Countdown, { zeroPad } from "react-countdown";
import { detailByEmail, getDashBoardDetails, getRewardData, getRewardTimer, leveWiseCount } from "../../../helpers/api";
import { useSelector } from "react-redux";
import { Loader } from "../Dashboard/Loader";

const RewardStatus = () => {


  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const { userToken } = useSelector((state) => state.auth);
  const walletDetails = JSON.parse(localStorage.getItem("walletDetails"));
  const walletAddress = walletDetails?.wallet;
  const userId = userDetails?.userId;
  const [apidata, setApidata] = useState('');
  const [timertimeStamp, setTimerTimestamp] = useState("");
  const [userData, setUserData] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchTimer = () => {
      let formData = { userId: userId };
      getRewardTimer(formData)
        .then((res) => {

          setTimerTimestamp(res);
        })
        .catch((err) => {
          console.error("Error fetching timer:", err);
        });
    };

    fetchTimer();
    const formData = {
      packageId: userToken?.value,
      wallet_address: walletAddress,
    };




    getDashBoardDetails(formData).then((res) => {


      setUserData(res);
    });
  }, [userId, walletAddress, userToken]);
  useEffect(() => {
    const fData = {
      user: walletAddress,
      packageId: userToken?.value,
    };
    leveWiseCount(fData).then((res) => {
      // console.log(res, "res");
      setApidata(res);
      setLoading(false);
    });
  }, [userToken, walletAddress]);

  const Completionist = () => <span className="text-danger">Expired</span>;

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <span className="header-label fs-14 grey">
          <span className="rounded" style={{ width: "56px" }}>
            Days {zeroPad(days)}
          </span>
          :{" "}
          <span className="rounded ms-1">
            {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
          </span>
        </span>
      );
    }
  };
  console.log(userData , "userData:::")

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-xxl-12">
            <div className="card">
              <Tab.Container defaultActiveKey="All">
                <div className="card-header border-0 pb-2 flex-wrap">
                  <h4 className="heading me-2">Reward Status</h4>

                  {/* 
                  <h2>  <Countdown
                    date={(moment(timertimeStamp?.date*1000).valueOf())}
                    renderer={renderer}
                  /></h2> */}
                </div>
                {userData?.rewardDistributeTime > 0 ?
                  <div className="card-body pt-2">
                    <div
                      id="history_wrapper"
                      className="table-responsive dataTablehistory"
                    >
                      {loading ? (
                        <Loader />
                      ) : (
                        <div className="dataTables_wrapper no-footer">
                          <table
                            id="example"
                            className="table shadow-hover dataTable display"
                            style={{ minWidth: "845px" }}
                          >
                            <thead>
                              <tr>


                                <th>Level</th>
                                <th>Per Members Target </th>
                                <th>Tota Member Level Wise</th>
                                <th>Reward Bonus</th>

                              </tr>
                            </thead>
                            <tbody>

                              <tr >
                                <td>
                                  Level 3:
                                </td>
                                <td>12</td>
                                <td>{apidata?.count?.level3}</td>


                                <td>
                                  200$
                                </td>


                              </tr>
                              <tr >
                                <td>
                                  Level 4
                                </td>

                                <td>32 </td>
                                <td>{apidata?.count?.level4}</td>
                                <td>
                                  500$
                                </td>


                              </tr>

                              <tr >
                                <td>
                                  Level 5
                                </td>

                                <td>100 </td>
                                <td>{apidata?.count?.level5}</td>
                                <td>
                                  2000$
                                </td>


                              </tr>
                              <tr >
                                <td>
                                  Level 6
                                </td>

                                <td>300 </td>
                                <td>{apidata?.count?.level6}</td>
                                <td>
                                  5000$
                                </td>


                              </tr>



                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>


                  </div> : <div className="text-center mt-4 mb-2 fw-bold fs-5 dash-heading">
                    No Data Found.
                  </div>}
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardStatus;
