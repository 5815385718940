import { LuBoxes } from "react-icons/lu";
import { BiMoneyWithdraw, BiStreetView } from "react-icons/bi";
import { VscSymbolConstant } from "react-icons/vsc";
import { GrGroup } from "react-icons/gr";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { FaStaylinked, FaTicketAlt } from "react-icons/fa";
import { FcOnlineSupport } from "react-icons/fc";
export const MenuList = [



  {
    title: "DashBoard",
    classsChange: "mm-collapse",
    iconStyle: <VscSymbolConstant size={30} color="#fff" />,
    to: "/dashboard",
  },
  {
    title: "Referral Range ",
    classsChange: "mm-collapse",
    iconStyle: <AiOutlineUsergroupAdd />,
    to: "/levelReport",
  },
  {
    title: "Level Report",
    classsChange: "mm-collapse",
    iconStyle: <AiOutlineUsergroupAdd />,
    to: "/levelWisereport",
  },



  {
    title: "Tree Structure",
    classsChange: "mm-collapse",
    iconStyle: <AiOutlineUsergroupAdd />,
    to: "/treeStructure",
  },

  {
    title: "Received Donation",
    classsChange: "mm-collapse",
    iconStyle:<FaStaylinked size={30} color="#fff" />,

    to: "/Level",
  },


  {
    title: "Reward Bonus",
    classsChange: "mm-collapse",
    iconStyle: <FaStaylinked size={30} color="#fff" />,
    to: "/reward",
  },
  // {
  //   title: "Reward Status",
  //   classsChange: "mm-collapse",
  //   iconStyle: <FcOnlineSupport  />,
  //   to: "/rewardStatus",
  // },

  {
    title: "Chat History →",
    classsChange: "mm-collapse",
    iconStyle: <FcOnlineSupport />,
    to: "/chat",
  },

  {
    title: "FeedBack →",
    classsChange: "mm-collapse",
    iconStyle: <FcOnlineSupport />,
    to: "/feedback",
  },

];
