import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import { deleteRange, getLevelTeam, getRangeData, updateExpectedMember } from "../../../helpers/api";
import moment from "moment/moment";
import { Pagination } from "@mui/material";
import { Loader } from "../Dashboard/Loader";
import toast from "react-hot-toast";
import Select from "react-select";


const LevelWiseFilter = () => {
    const options2 = [
        { value: "1", label: "Level 1" },
        { value: "2", label: "Level 2" },
        { value: "3", label: "Level 3" },
        { value: "4", label: "Level 4" },
        { value: "5", label: "Level 5" },
        { value: "6", label: "Level 6" },
    ];

    const [selectedOption, setSelectedOption] = useState(options2[0]);
    const { userToken } = useSelector((state) => state.auth);
    const walletDetails = JSON.parse(localStorage.getItem("walletDetails"));
    const walletAddress = walletDetails?.wallet;
    const [apidata, setApidata] = useState([]);
    const [steps, setSteps] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);

   
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const userId = userDetails?.userId;

    const getLevelData = (wallet, page, level, token, t) => {
        getLevelTeam(wallet, level, page, token).then((res) => {

             
            setTotal(res?.totalTeam);

            setApidata(res?.res);
            setLoading(true);
        });
    };

    useEffect(() => {
        getLevelData(walletAddress, page, selectedOption.value, userToken?.value, 0);
    }, [walletAddress, page, selectedOption, userToken]);

    const handleChange = (e, p) => {
        getLevelData(walletAddress, p, selectedOption.value, userToken?.value);
        setPage(p);
    };


    return (
        <div>
            {!loading ? (
                <Loader />
            ) : (
                <Tab.Container defaultActiveKey="Buy">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body pb-0">
                                    <div className="d-flex flex-wrap">

                                        <div className="ps-1 pe-1">

                                            <Select
                                                options={options2}
                                                defaultValue={options2[0]}
                                                onChange={setSelectedOption}
                                                className="custom-react-select ms-4 me-2"
                                            />
                                        </div>


                                    </div>
                                </div>
                                <div className="card-body">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="Buy">
                                            <Tab.Container defaultActiveKey="BTH">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h4 className="heading text-dark">Level Wise  Report</h4>
                                                </div>
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="BTH">
                                                        <div className="table-responsive dataTablehistory">
                                                            <table className="table dataTable shadow-hover display" style={{ minWidth: "845px" }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th className="text-dark">User Address</th>
                                                                        <th className="text-dark">User Id</th>
                                                                        <th className="text-dark">Level</th>
                                                                        <th className="text-dark">Direct Member</th>
                                                                     
                                                                        <th className="text-dark">Email</th>

                                                                        <th className="text-dark">Date</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {apidata?.length > 0 ? (
                                                                        apidata?.map((data, ind) => {

                                                                      
                                                                            return (
                                                                                <tr key={ind}>
                                                                                    <td>
                                                                                        {data?.address?.slice(0, 6)}...
                                                                                        {data?.address?.slice(-5)}
                                                                                    </td>
                                                                                    <td>{data?.userId}</td>
                                                                                    <td>{data?.level + 1}</td>
                                                                                    <td>{data?.direct_member}</td>
                                                                                 
                                                                                    <td>{data?.email}</td>
                                                                                   
                                                                                    <td> {moment(
                                                                                                (data?.time)
                                                                                            ).fromNow()}

                                                                                            /{new Date(data.time)?.toISOString()?.slice(0, 10)}</td>
                                                                                </tr>
                                                                            );
                                                                        })
                                                                    ) : (
                                                                        <tr>
                                                                            <td colSpan={7}>
                                                                                <div className="text-center mt-4 mb-2 fw-bold fs-5 dash-heading">No Data Found.</div>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                            <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                                                                <div className="dataTables_paginate paging_simple_numbers mb-0">
                                                                    <Pagination
                                                                        color="primary"
                                                                        count={Math.ceil(total / 10)}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Tab.Container>
                                        </Tab.Pane>
                                    </Tab.Content>

                                </div>
                            </div>
                        </div>
                    </div>
                </Tab.Container>
            )}
        </div>
    );
};

export default LevelWiseFilter;
