import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./tree.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getUserDetailsByWallet, getTreeView, detailByEmail } from "../../../helpers/api";
import { Loader } from "../Dashboard/Loader";
import logo from "../../../dmt1.png";
import moment from "moment";
import { FaUserAlt } from "react-icons/fa"; // Import the icon

const TreeStructure = () => {
    const { userToken } = useSelector((action) => action.auth);
    const walletDetails = JSON.parse(localStorage.getItem("walletDetails"));
    const walletAddress = walletDetails?.wallet;
    const [apidata, setApidata] = useState([]);
    const [loading, setLoading] = useState(false);
    const [steps, setSteps] = useState([walletAddress]);
    const [userId, setUserId] = useState('');

    // Fetches level data recursively and handles tree structure for 3 levels
    async function getLevelData(address, ar = [], packageId, depth = 3) {
        const treedata = { name: "", children: [] };

        try {
            const apidata = await getUserDetailsByWallet(address, Number(packageId));
            treedata.dateofJoining = apidata?.userDetails?.createdAt;
            treedata.direct = apidata?.userDetails?.direct_member;
            treedata.id = apidata?.userDetails?.userId;

            const treeData = await getTreeView(address, packageId);

            // Fetch children up to the specified depth
            if (treeData?.length > 0 && depth > 0) {
                treedata.children = await Promise.all(treeData.map(async (item) => {
                    const childData = await getUserDetailsByWallet(item?.user, packageId);
                    return {
                        name: item?.userId.toString(),
                        children: [],
                        ...item,
                        dateofJoining: childData?.userDetails?.createdAt,
                        direct: childData?.userDetails?.direct_member,
                        children: await fetchChildren(item?.user, depth - 1, packageId)
                    };
                }));
            }

            setApidata([treedata]);
            setLoading(true);
            if (ar.length > 0) setSteps(ar);
        } catch (e) {
            console.error("Error fetching data", e);
            setLoading(true);
            setApidata([treedata]);
        }
    }

    // Fetch children recursively, limit by depth
    async function fetchChildren(address, depth, packageId) {
        if (depth <= 0) return [];

        const treeData = await getTreeView(address, packageId);
        return await Promise.all(treeData.map(async (item) => {
            const childData = await getUserDetailsByWallet(item?.user, packageId);
            return {
                name: item?.userId.toString(),
                children: [],
                ...item,
                dateofJoining: childData?.userDetails?.createdAt,
                direct: childData?.userDetails?.direct_member,
                children: await fetchChildren(item?.user, depth - 1, packageId)
            };
        }));
    }

    useEffect(() => {
        getLevelData(walletAddress, [walletAddress], userToken?.value, 3);
    }, [walletAddress, userToken]);

    const handleNodeClick = (chl) => {
        const ar = [...steps, chl.user];
        getLevelData(chl.user, ar, userToken?.value, 2);
    };

    const tooltip = (chl) => (
        <Tooltip id="multi-api-tooltip">
            <strong>User_id:</strong> {chl?.userId} <br />
            <strong>Date of Joining:</strong> {moment(chl?.dateofJoining).format("DD-MM-YYYY")} <br />
        </Tooltip>
    );

    const tooltipMain = (item) => (
        <Tooltip id="multi-api-tooltip">
            <strong>User_id:</strong> {item?.id} <br />
            <strong>Date of Joining:</strong> {moment(item?.dateofJoining).format("DD-MM-YYYY")} <br />
        </Tooltip>
    );

    async function searchById(id) {
        const formData = {
            packageId: userToken?.value,
            userId: id,
        };
        try {
            const res = await detailByEmail(formData);
            if (res?.data?.user) {
                setUserId('');
                await getLevelData(res.data.user, [], userToken.value, 3);
            }
        } catch (e) {
            console.error("Error searching by ID:", e);
        }
    }

    return (
        <div className="card">
            <div className="card-body">
                <section className="pb-5" style={{ height: "100%" }}>
                    {!loading ? (
                        <Loader />
                    ) : (
                        <div className="container">
                            <div className="message-input">
                                <input
                                    type="text"
                                    placeholder="View by User Id..."
                                    value={userId}
                                    onChange={(e) => setUserId(e.target.value)}
                                />
                                <button onClick={() => searchById(userId)}>Submit</button>
                            </div>
                            <h3 className="my-3 " style={{ textAlign: "center" }}>
                                Your Level Tree
                            </h3>
      <h4 className="text-info text-center">Level {steps?.length}</h4>
                            <div className="row">
                                <button
                                    className="btn userTree"
                                    style={{
                                        border: "1px solid rgb(179 179 179)",
                                        width: "max-content",
                                        background: "#a5814e",
                                        color: "#fff"
                                    }}
                                    onClick={async () => {
                                        let ar = [...steps];
                                        if (ar.length > 1) {
                                            ar.pop();
                                            const previousAddress = ar[ar.length - 1];
                                            setSteps(ar);
                                            await getLevelData(previousAddress, ar, userToken?.value);
                                        } else {
                                            setSteps([walletAddress]);
                                            await getLevelData(walletAddress, [walletAddress], userToken?.value);
                                        }
                                    }}
                                >
                                    {"< Go back"}
                                </button>
                                <button
                                    className="btn userTree mx-1"
                                    style={{
                                        border: "1px solid rgb(179 179 179)",
                                        width: "max-content",
                                        background: "#a5814e",
                                        color: "#fff"
                                    }}
                                    onClick={() => getLevelData(walletAddress, [walletAddress], userToken?.value, 3)}
                                >
                                    {"Root"}
                                </button>
                                <div className="tree col-lg-12 col-md-12 mx-auto treresp">
                                    {apidata?.length > 0 &&
                                        apidata.map((item) => (
                                            <ul
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                                key={item.id}
                                            >
                                                <li>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={tooltipMain(item)}
                                                    >
                                                        <a
                                                            href="#"
                                                            className="custom-style"
                                                            style={{ width: "100px", height: "100px", border: '0.3px solid rgb(182 182 182)' }}
                                                        >
                                                            <div>
                                                                <img src={logo} alt="" width={50} />
                                                                <p className="text-center  pt-1" style={{ fontSize: '14px' }}>
                                                                    {item?.id}
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </OverlayTrigger>
                                                    <ul className="subtree table-responsive pb-3">
                                                        {item?.children.map((chl) => (
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={tooltip(chl)}
                                                                key={chl?.userId}
                                                            >
                                                                <li onClick={() => handleNodeClick(chl)}>
                                                                    <a
                                                                        href="#"
                                                                        style={{ width: "100px", height: "100px", border: '0.3px solid rgb(182 182 182)' }}
                                                                    >
                                                                        <div className="p-0">
                                                                            <img src={logo} alt="" width={50} />
                                                                            <p className="text-center  pt-1" style={{ fontSize: '14px' }}>
                                                                                {chl?.userId}
                                                                            </p>
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                            </OverlayTrigger>
                                                        ))}
                                                        {item?.children.length < 4 &&
                                                            [...Array(4 - item.children.length)].map((_, index) => (
                                                                <li key={index}>
                                                                    <a
                                                                        href="#"
                                                                        style={{
                                                                            width: "100px",
                                                                            height: "100px",
                                                                            border: '0.3px solid rgb(182 182 182)',
                                                                            background: "#f1f1f1",
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center"
                                                                        }}
                                                                    >
                                                                        <div className="vacant-slot">
                                                                            {/* Use the static icon for vacant slots */}
                                                                            <FaUserAlt size={40} color="#b0b0b0" />
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                            ))}
                                                    </ul>
                                                </li>
                                            </ul>
                                        ))}
                                </div>
                            </div>
                        </div>
                    )}
                </section>
            </div>
        </div>
    );
};

export default TreeStructure;
